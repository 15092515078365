import React, { useRef, useState, useEffect } from "react";
import Meyda from "meyda";

const music = [
    'music/Hinterlands.mp3',
    'music/Invisible.mp3',
    'music/Puzzle.mp3'
]

const AudioAnalyzer = ({ onAudioProcess, onScenesDetected }) => {
    const audioRef = useRef();
    const audioContextRef = useRef();
    const analyserRef = useRef();
    const dataArrayRef = useRef();
    const [isAudioReady, setIsAudioReady] = useState(false);
    const [scenes, setScenes] = useState([{}]);
    const [inputParams, setInputParams] = useState({
        rmsChange: 0.01,
        zcrChange: 2,
        spectralChange: 4,
    });
    const [outputParams, setOutputParams] = useState({
        rmsChange0: 0,
        rmsChange1: 0,
        rmsChange2: 0,
        zcrChange0: 0,
        zcrChange1: 0,
        zcrChange2: 0,
        spectralCentroidChange0: 0,
        spectralCentroidChange1: 0,
    })

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const url = URL.createObjectURL(file);
        audioRef.current.src = url;

        if (!audioContextRef.current) {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContextRef.current = audioContext;

            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 256;
            analyserRef.current = analyser;

            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            dataArrayRef.current = dataArray;

            const audioSource = audioContext.createMediaElementSource(audioRef.current);
            audioSource.connect(analyser);
            analyser.connect(audioContext.destination);

            audioRef.current.onplay = () => {
                analyzeAudio();
                setIsButtonVisible(false);
            };
        }

        setIsAudioReady(true);
    };

    const calculateMovingAverage = (data, windowSize) => {
        let sum = 0;
        const result = [];

        for (let i = 0; i < data.length; i++) {
            sum += data[i];
            if (i >= windowSize) {
                sum -= data[i - windowSize];
            }
            result.push(sum / Math.min(i + 1, windowSize));
        }

        return result;
    };

    const analyzeAudio = () => {
        const analyser = analyserRef.current;
        const timeDomainData = new Float32Array(analyser.fftSize);
        const frequencyData = new Uint8Array(analyser.frequencyBinCount);
        let lastScene = null;
        const rmsValues = [];
        const zcrValues = [];
        const spectralCentroidValues = [];
        const windowSize = 500; // Размер окна для скользящего среднего

        const update = () => {
            if (audioRef.current.paused) return;

            analyser.getFloatTimeDomainData(timeDomainData);
            analyser.getByteFrequencyData(frequencyData);
            const features = Meyda.extract(['rms', 'zcr', 'spectralCentroid'], timeDomainData);

            if (features) {
                rmsValues.push(features.rms);
                zcrValues.push(features.zcr);
                spectralCentroidValues.push(features.spectralCentroid || 0);

                const rmsAvg = calculateMovingAverage(rmsValues, windowSize).slice(-1)[0];
                const zcrAvg = calculateMovingAverage(zcrValues, windowSize).slice(-1)[0];
                const spectralCentroidAvg = calculateMovingAverage(spectralCentroidValues, windowSize).slice(-1)[0];

                const newScene = {
                    time: audioRef.current.currentTime,
                    rms: rmsAvg,
                    zcr: zcrAvg,
                    spectralCentroid: spectralCentroidAvg,
                };

                if (!lastScene) {
                    lastScene = {...newScene, startTime: audioRef.current.currentTime};
                } else if (isSignificantChange(newScene, lastScene) && audioRef.current.currentTime - lastScene.startTime > 2) {
                    lastScene.endTime = audioRef.current.currentTime;
                    setScenes((prevScenes) => [...prevScenes, lastScene]);
                    lastScene = {...newScene, startTime: audioRef.current.currentTime};
                }

                onAudioProcess(frequencyData); // Передаем данные для визуализатора
            } else {
                console.error('Meyda failed to extract features');
            }

            requestAnimationFrame(update);
        };

        update();
    };

    // const isSignificantChange = (newScene, lastScene) => {
    //     const rmsChange = Math.abs(newScene.rms - lastScene.rms) > 49;
    //     const zcrChange = newScene.zcr && lastScene.zcr && Math.abs(newScene.zcr - lastScene.zcr) > 0.01;
    //     const spectralChange = newScene.spectralCentroid && lastScene.spectralCentroid && !isNaN(newScene.spectralCentroid) && !isNaN(lastScene.spectralCentroid) && Math.abs(newScene.spectralCentroid - lastScene.spectralCentroid) > 200;
    //
    //     console.log(Math.abs(newScene.rms - lastScene.rms));
    //
    //     return rmsChange || zcrChange || spectralChange;
    // };
    // const rmsChange = outputParams.rmsChange1 > 0.01 && (outputParams.rmsChange2 > 1.3 || outputParams.rmsChange2 < 0.9);
    // const zcrChange = (outputParams.zcrChange1 > 2 && outputParams.zcrChange2 < .8) || (outputParams.zcrChange1 > 6);
    // const spectralChange = outputParams.spectralCentroidChange1 > 4;
    // const rmsChange = outputParams.rmsChange1 > 0.01 && (outputParams.rmsChange2 > 1.4 || outputParams.rmsChange2 < 0.8);
    // const zcrChange = (outputParams.zcrChange1 > 2 && outputParams.zcrChange2 < .8) || (outputParams.zcrChange1 > 6);
    // const spectralChange = outputParams.spectralCentroidChange1 > 8;

    const isSignificantChange = (newScene, lastScene) => {
        const outputParams = {
            rmsChange0: newScene.rms,
            rmsChange1: Math.abs(newScene.rms - lastScene.rms),
            rmsChange2: newScene.rms / lastScene.rms,
            zcrChange0: newScene.zcr,
            zcrChange1: Math.abs(newScene.zcr - lastScene.zcr),
            zcrChange2: newScene.zcr / lastScene.zcr,
            spectralCentroidChange0: newScene.spectralCentroid,
            spectralCentroidChange1: Math.abs(newScene.spectralCentroid - lastScene.spectralCentroid),
        }

        const rmsChange = outputParams.rmsChange1 > 0.01 && (outputParams.rmsChange2 > 1.3 || outputParams.rmsChange2 < 0.9);
        const zcrChange = (outputParams.zcrChange1 > 2 && outputParams.zcrChange2 < .8) || (outputParams.zcrChange1 > 6);
        const spectralChange = outputParams.spectralCentroidChange1 > 4;

        setOutputParams(outputParams);

        return rmsChange || zcrChange || spectralChange;
    };

    useEffect(() => {
        onScenesDetected(scenes);
    }, [scenes]);

    const [isButtonVisible, setIsButtonVisible] = useState(true);

    const handlePlay = () => {
        const randomIndex = Math.floor(Math.random() * music.length);
        const url = music[randomIndex];
        audioRef.current.src = url;

        if (!audioContextRef.current) {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContextRef.current = audioContext;

            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 256;
            analyserRef.current = analyser;

            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            dataArrayRef.current = dataArray;

            const audioSource = audioContext.createMediaElementSource(audioRef.current);
            audioSource.connect(analyser);
            analyser.connect(audioContext.destination);

            audioRef.current.onplay = () => {
                analyzeAudio();
                setIsButtonVisible(false);
            };
        }

        setIsAudioReady(true);

        audioRef.current.play();
    };

    return (
        <div>
            {isButtonVisible && (
                <button className="play-button" onClick={handlePlay}>▶</button>
            )}
{/*
            <div>
                <input type="number" value={inputParams.rmsChange}
                       onChange={(e) => setInputParams({...inputParams, rmsChange: e.target.value})}/>
                <input type="number" value={outputParams.rmsChange0}/>
                <input type="number" value={outputParams.rmsChange1}/>
                <input type="number" value={outputParams.rmsChange2}/>
            </div>
            <div>
                <input type="number" value={inputParams.zcrChange}
                       onChange={(e) => setInputParams({...inputParams, zcrChange: e.target.value})}/>
                <input type="number" value={outputParams.zcrChange0}/>
                <input type="number" value={outputParams.zcrChange1}/>
                <input type="number" value={outputParams.zcrChange2}/>
            </div>
            <div>
                <input type="number" value={inputParams.spectralChange}
                       onChange={(e) => setInputParams({...inputParams, spectralChange: e.target.value})}/>
                <input type="number" value={outputParams.spectralCentroidChange0}/>
                <input type="number" value={outputParams.spectralCentroidChange1}/>
            </div>
*/}
            <input type="file" accept="audio/*" onChange={handleFileUpload}/>
            <audio ref={audioRef} controls/>
        </div>
    );
};

export default AudioAnalyzer;