import React, {useState, useCallback, useRef} from "react";
import AudioAnalyzer from "./components/AudioAnalyzer";
import Visualizer from "./components/Visualizer2";
import Timeline from "./components/Timeline";
import GeometryDashVisualizer from "./components/GeometryDashVisualizer";
import "./App.css";

const App = () => {
    const [audioData, setAudioData] = useState([]);
    const [scenes, setScenes] = useState([]);

    const handleAudioProcess = useCallback((data) => {
        setAudioData(data);
    }, []);

    const handleScenesDetected = (detectedScenes) => {
        setScenes(detectedScenes);
    };

    return (
        <div>
            {/*<Visualizer audioData={audioData} scenes={scenes} />*/}
            {/*<Timeline scenes={scenes} />*/}
            <GeometryDashVisualizer audioData={audioData} scenes={scenes} />
            <AudioAnalyzer
                onAudioProcess={handleAudioProcess}
                onScenesDetected={handleScenesDetected}
            />
        </div>
    );
};

export default App;